<template>
	<v-list-group v-if="minimized">
		<template v-slot:activator>
			<v-list-tile avatar>
				<v-list-tile-avatar color="primary">{{ initials }}</v-list-tile-avatar>
				<v-list-tile-content>
					<v-list-tile-title v-t="'profile.title'" />
				</v-list-tile-content>
			</v-list-tile>
		</template>
		<ProfileSettings />
		<v-list-tile avatar @click.stop="darkMode = !darkMode">
			<v-list-tile-avatar>
				<v-icon>invert_colors</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-switch color="primary" :input-value="darkMode" :label="$t('actions.dark_mode')" />
			</v-list-tile-content>
		</v-list-tile>
		<v-list-tile avatar @click="logout()">
			<v-list-tile-avatar>
				<v-icon>exit_to_app</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title>{{ $t('actions.disconnect') }}</v-list-tile-title>
			</v-list-tile-content>
		</v-list-tile>
	</v-list-group>
	<v-menu v-else offset-y>
		<template v-slot:activator="{ on: menu }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip }">
					<v-avatar class="pointer" color="primary" v-on="{ ...menu, ...tooltip }">
						<v-flex title white--text v-text="initials" />
					</v-avatar>
				</template>
				<span v-t="'profile.title'" />
			</v-tooltip>
		</template>
		<v-list>
			<v-subheader v-if="fullname" bold ma-2 text-truncate title>{{ fullname + ' (' + email + ')' }}</v-subheader>
			<v-subheader v-else>{{ email }}</v-subheader>
			<v-divider />
			<v-list-tile avatar @click="goTo('profile-user')">
				<v-list-tile-avatar>
					<v-icon>settings_applications</v-icon>
				</v-list-tile-avatar>
				<v-list-tile-content>
					<v-list-tile-title>{{ $t('user-settings.name') }}</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>
			<v-list-tile avatar @click.stop="darkMode = !darkMode">
				<v-list-tile-avatar>
					<v-icon>invert_colors</v-icon>
				</v-list-tile-avatar>
				<v-list-tile-content>
					<v-switch color="primary" :input-value="darkMode" :label="$t('actions.dark_mode')" />
				</v-list-tile-content>
			</v-list-tile>
			<v-list-tile avatar @click="logout()">
				<v-list-tile-avatar>
					<v-icon>exit_to_app</v-icon>
				</v-list-tile-avatar>
				<v-list-tile-content>
					<v-list-tile-title>{{ $t('actions.disconnect') }}</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>
		</v-list>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'
import AuthService from '@/services/Auth/AuthService'
import WhiteLabelService from '@/services/WhiteLabel/WhiteLabelService'

export default {
	name: 'UserMenu',
	components: {
		ProfileSettings: () => ({
			component: import('@/components/Profile/ProfileSettings')
		})
	},
	mixins: [AppModuleGuard],
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: () => false
		}
	},
	computed: {
		...mapState({
			email: state => state.user.email,
			fullname: state => state.user.fullname,
			initials: state => state.user.initials
		}),
		darkMode: {
			get: function () {
				return this.$store.state.user.darkMode
			},
			set: function (val) {
				return WhiteLabelService.setDarkMode(val)
			}
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [{ event: this.appEvents.LOGOUT, action: this.logout }]
		},
		goTo: function (routePath) {
			this.appService.goTo(routePath)
		},
		logout: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			const message = this.$t('profile.messages.disconnected')
			return AuthService.logout().finally(() => {
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, 0)
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, message)
			})
		}
	}
}
</script>
